/* eslint-disable indent */
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    GetItemRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/warehouse/material-orders/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getReleaseOrder(ctx, { warehouse, department }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/get-warehsouse-content-warehouse/${warehouse}/${department}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetRequestPurches(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/show-transaction/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetItem() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/item')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      GetPurchesItem() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/purchase-order')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      GetWareHouse() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/warehouses')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      getItemExpirationDate() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/get-item-expiration-date-notification')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      GetDepartment() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/departments')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      GetAccount() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/donor-account')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      GetDonor() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/donor')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      
      GetSubAccounts() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/finance/get-accounts-list')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      GetBiller() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/donor-biller')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      GetCurrency() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/currency')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      GetOutCome() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/donor-outcome')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      GetAllWarehouses() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/warehouses')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      GetUsers() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/get-all-users')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      GetoutPut() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/donor-ouput')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      GetUnits() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/unit')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      getDetailsItem(ctx) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/get-material-order-drop-down-list')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      getMaterialOrder(ctx,id) {
  
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/v1/warehouse/material-orders/${id}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      getPartyType(ctx, { id }) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/v1/get-party/${id}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      getPartyTypeWarehouse(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/get-party/2')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      getParentTypeWarehouse(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/get-parent-warehouses')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      getChildTypeWarehouse(ctx,id) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/v1/get-child-warehouses/${id}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      getDetails(ctx, { itemId, WarehouseId }) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/v1/item-transactions/${itemId}/${WarehouseId}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      getAvialableItem(ctx, { department, WarehouseId, data }) {
        return new Promise((resolve, reject) => {
          axios
            .post(`/api/v1/check-item-avalibality/${WarehouseId}/${department}`, data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      sendResponse(ctx,approvestate) {
        return new Promise((resolve, reject) => {
          let data={
            status:approvestate.status
          }
  
          let url=`/api/v1/approve-material-order/${approvestate.id}`
          axios
            .post(url,data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
  
      },
      GetNotifications() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/get-user-unread-notifications')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      readNotification() {
        return new Promise((resolve, reject) => {
          axios
            .post('/api/v1/set-user-notifications-as-read')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      GetAllNotifications() {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/get-all-user-notifications')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
      getSubAccount(ctx, { id }) {
        // console.log("id",id)
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/v1/get-donor-sub-accounts-list/${id}`)
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      getSubAccountById(ctx, id) {
        // console.log("id",id)
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/v1/get-donor-sub-accounts-list/${id}`)
            .then(response => resolve(response.data.data))
            .catch(error => reject(error))
        })
      },
      fetchRequestItem(ctx, queryParams) {
      return new Promise((resolve, reject) => {
axios.get('/api/v1/warehouse/material-orders', {  params:queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRejectedRequestItem(ctx, queryParams) {
      console.log(queryParams)
      return new Promise((resolve, reject) => {
axios.get('/api/v1/get-user-material-orders', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getEmployy(ctx, id) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/v1/main_services/${id}/employees`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },

    requestItem(ctx, payload) {
      return new Promise((resolve, reject) => {
      const url = '/api/v1/warehouse/material-orders'
        axios
          .post(url, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    UpdateRequestItem(ctx, payload) {
      return new Promise((resolve, reject) => {
      const url = `/api/v1/warehouse/material-orders/${payload.id}`
        axios
          .put(url, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    NewPaymentOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
      const url = '/api/v1/payment-order'
        axios
          .post(url, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    financeTransactions(ctx, payload) {
      return new Promise((resolve, reject) => {
      const url = '/api/v1/finance/transactions'
        axios
          .post(url, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    createTransaction(ctx, payload) {
      return new Promise((resolve, reject) => {
      const url = '/api/v1/create-transaction'
        axios
          .post(url, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    afterCreateTransaction(ctx, payload) {
      console.log(payload)
      let data={
        pr_code:payload.pr_code
      }
      return new Promise((resolve, reject) => {
      const url =`/api/v1/add-code-of-transaction-entered/${payload.transaction_id}`
        axios
          .post(url, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    CompletecreateTransaction(ctx, payload) {
      return new Promise((resolve, reject) => {
      const url = `/api/v1/finance/complete-procurment-order/${payload.id}`
        axios
          .post(url, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    completeTransaction(ctx, payload) {
      return new Promise((resolve, reject) => {
      const url = `/api/v1/complete-procurement-transactions/${payload.id}`
        axios
          .post(url, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
