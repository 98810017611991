export default [

  {
    title: '   إدارة المعلومات ',
    icon: 'DatabaseIcon',

    children: [
      // {
      //   title: '   إدارة الموقع',
      //   icon: 'DatabaseIcon',
      // route: 'manageSite',
      // resource: 'modification_requests',
      // action: 'read',
      // },

      {
        title: 'نقاط الضعف',
        icon: 'FileIcon',
        route: 'add-weakpoint',
        resource: 'weaknesses',
        action: 'read',
      },
      {
        title: ' الامراض',
        icon: 'FileIcon',
        route: 'add-diseases',
        resource: 'diseases',
        action: 'read',
      },
      {
        title: '   طلبات التعديل',
        icon: 'UserIcon',
        route: 'modification-requests',
        resource: 'modification_requests',
        action: 'read',
      },
      {
        title: '   طلبات الإضافة',
        icon: 'UserIcon',
        route: 'additional-requests',
        resource: 'modification_requests',
        action: 'read',
      },
      {
        title: 'الوثائق',
        icon: 'FileIcon',
        route: 'add-document',
        resource: 'all',
        action: 'manage',
      },
      
      {
        title: 'حالات الافراد',
        icon: 'FileIcon',
        route: 'add-person-status',
        resource: 'all',
        action: 'manage',
      },
      {
        title: '   المواقع',
        icon: 'FileIcon',
        route: 'CountryList',
        resource: 'all',
        action: 'manage',
      },
      {
        title: '   إضافة موقع',
        icon: 'FileIcon',
        route: 'add-location',
        resource: 'all',
        action: 'manage',
      },
      {
        title: '  الاقسام',
        icon: 'FileIcon',
        route: 'department',
        resource: 'all',
        action: 'manage',
      },
      {
        title: 'إضافة خدمة',
        icon: 'FileIcon',
        route: 'add-service',
        resource: 'all',
        action: 'manage',
      },
      {
        title: '   الاعمال',
        icon: 'FileIcon',
        route: 'add-work',
        resource: 'works',
        action: 'read',
      },
    ],
  },
 
  
  {
    title: 'المستخدمون',
    icon: 'UserIcon',
    children: [{
      title: 'كل المستخدمون',
      icon: 'UserIcon',
      route: 'apps-users-list',
      resource: 'users',
      action: 'read',
    },

    ],

  },
  

 
   
 
   
    

  // SESSIONS 


  
    
    
   
    
   
    
   


  {
    title: 'العائلات',
    icon: 'UserIcon',

    route: 'all-families',

    children: [{
      title: 'كل العائلات',
      icon: 'UserIcon',
      route: 'all-families',
      resource: 'beneficiaries',
      action: 'read',
    },
    

 
    {
      title: 'كل الافراد',
      icon: 'UserIcon',
      route: 'all-benificary',
      resource: 'all_benificary',
      action: 'all',
    },
    {
      title: '   العائلات غير المستكملة',
      icon: 'UserIcon',
      route: 'un-completed-families',
      resource: 'beneficiaries',
      action: 'read',
    },
    {
      title: '  إضافة مستفيد',
      icon: 'UserIcon',
      route: 'add-beneficiary',
      resource: 'beneficiaries',
      action: 'read',
    },
    ],
    // },
  },

 
  
 
  // {
  //   title: 'التحويلات الداخلية',
  //   icon: 'FileTextIcon',
  //   route: 'transfer-list',
  //   resource: 'services',
  //   action: 'read',

  // },
  // {
  //   title: '  طلبات بدء الحالة',
  //   icon: 'FileTextIcon',
  //   route: 'request-auditing',
  //   resource: 'services',
  //   action: 'read',

  // },
  {
    title: 'الخدمات',
    icon: 'FileTextIcon',
    children: [{
      title: '  خدمات الافراد',
      icon: 'FileTextIcon',
      route: 'service-list',
      resource: 'services',
      action: 'read',

    },

    {
      title: '  خدمات العائلة',
      icon: 'FileTextIcon',
      route: 'services-families-list',
      resource: 'services',
      action: 'read',

    }],
  },
  {
    title: '  المراجعات/ الشكاوى',
    icon: 'FileTextIcon',
    route: 'problems-list',
    resource: 'complaints',
    action: 'all',

  },

  {
    title: '  احصائيات',
    icon: 'FileTextIcon',

    children: [{

      title: '  احصائيات عامة',
      icon: 'FileTextIcon',
      route: 'dashboard-statistics',

      resource: 'statistics',
      action: 'read',

    },
    // {

    //   title: '  احصائيات الانجاز',
    //   icon: 'FileTextIcon',
    //   route: 'achievement_stats',

    //   resource: 'statistics',
    //   action: 'read',

    // },
    // {
    //   title: '  احصائيات متقدمة',
    //   icon: 'FileTextIcon',
    //   route: 'beneficiaries-list',
    //   resource: 'statistics',
    //   action: 'read',

    // }
  ],

  },

  // {
  //   title: '  صلاحيات الوصول',
  //   icon: 'FileTextIcon',
  //   route: 'all-roles',
  //   resource: 'roles',
  //   action: 'read',

  // },
  // {
  //   title: '  تقرير الخدمات  ',
  //   route: 'generate-service-report',
  //   icon: 'PlusIcon',
  // },
  // {
  //   title: '  تقرير نقاط الضعف',
  //   route: 'generate-report',
  //   icon: 'PlusIcon',
  // },

  // {
  //   title: 'استمارة حالات الطوارئ',
  //   route: 'emergency',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'استمارة التقييم',

  //   route: 'Assessment-Form',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'استبيان تقييم احتياج سريع',
  //   route: 'evaluation-question',
  //   icon: 'FileTextIcon',
  // },
  //   {
  //     title: 'الإجراءالخاص بالناجي ',

  //     route: 'Survivor-plan',
  //     icon: 'FileTextIcon',
  //   },

  // {
  //   title: ' ورقة الرصد ',

  //   route: 'monitoring-paper',
  //   icon: 'FileTextIcon',
  // },
  //   {
  //     title: ' الإحالة',

  //     route: 'Referral-Form',
  //     icon: 'FileTextIcon',
  //   },

  // {
  //   title: ' متابعة الحالة',
  //   route: 'Case-follow-up',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: ' إغلاق الحالة',
  //   route: 'case-close-up',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'الموافقة على نشر المعلومات',
  //   route: 'Consent-Form',
  //   icon: 'FileTextIcon',
  // },

  // {
  //   title: ' إحالة طبية',

  //   route: 'medical-referral',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'برنامج الدعم النفسي ',

  //   route: 'psychosocial-support',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: ' البرنامج التعليمي ',

  //   route: 'educationl-programs',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'المساعدات العينية',

  //   route: 'Assessment-aid',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'Email',
  //   route: 'apps-email',
  //   icon: 'MailIcon',
  // },
  // {
  //   title: 'Chat',
  //   route: 'apps-chat',
  //   icon: 'MessageSquareIcon',
  // },
  // {
  //   title: 'Todo',
  //   route: 'apps-todo',
  //   icon: 'CheckSquareIcon',
  // },
  // {
  //   title: 'Calendar',
  //   route: 'apps-calendar',
  //   icon: 'CalendarIcon',
  // },

  // {
  //   title: 'Invoice',
  //   icon: 'FileTextIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-invoice-list',
  //     },
  //     {
  //       title: 'Preview',
  //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Add',
  //       route: { name: 'apps-invoice-add' },
  //     },
  //   ],
  // },
  // {
  //   title: 'eCommerce',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'Shop',
  //       route: 'apps-e-commerce-shop',
  //     },
  //     {
  //       title: 'Details',
  //       route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
  //     },
  //     {
  //       title: 'Wishlist',
  //       route: 'apps-e-commerce-wishlist',
  //     },
  //     {
  //       title: 'Checkout',
  //       route: 'apps-e-commerce-checkout',
  //     },
  //   ],
  // },
  // {
  //   title: 'User',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-users-list',
  //     },
  //     {
  //       title: 'View',
  //       route: { name: 'apps-users-view', params: { id: 21 } },
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-users-edit', params: { id: 21 } },
  //     },
  //   ],
  // },
  // {
  //   title: 'Pages',
  //   icon: 'FileIcon',
  //   children: [
  //     {
  //       title: 'Authentication',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Login v1',
  //           route: 'auth-login-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Login v2',
  //           route: 'auth-login-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v1',
  //           route: 'auth-register-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v2',
  //           route: 'auth-register-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v1',
  //           route: 'auth-forgot-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v2',
  //           route: 'auth-forgot-password-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v1',
  //           route: 'auth-reset-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v2',
  //           route: 'auth-reset-password-v2',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Account Settings',
  //       route: 'pages-account-setting',
  //     },
  //     {
  //       title: 'Profile',
  //       route: 'pages-profile',
  //     },
  //     {
  //       title: 'Faq',
  //       route: 'pages-faq',
  //     },
  //     {
  //       title: 'Knowledge Base',
  //       route: 'pages-knowledge-base',
  //     },
  //     {
  //       title: 'Pricing',
  //       route: 'pages-pricing',
  //     },
  //     {
  //       title: 'Blog',
  //       children: [
  //         {
  //           title: 'List',
  //           route: 'pages-blog-list',
  //         },
  //         {
  //           title: 'Detail',
  //           route: { name: 'pages-blog-detail', params: { id: 1 } },
  //         },
  //         {
  //           title: 'Edit',
  //           route: { name: 'pages-blog-edit', params: { id: 1 } },
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Mail Templates',
  //       children: [
  //         {
  //           title: 'Welcome',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
  //         },
  //         {
  //           title: 'Reset Password',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
  //         },
  //         {
  //           title: 'Verify Email',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
  //         },
  //         {
  //           title: 'Deactivate Account',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
  //         },
  //         {
  //           title: 'Invoice',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
  //         },
  //         {
  //           title: 'Promotional',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Miscellaneous',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Coming Soon',
  //           route: 'misc-coming-soon',
  //           target: '_blank',
  //         },
  // {
  //   title: 'Not Authorized',
  //   route: 'misc-not-authorized',
  //   target: '_blank',
  // },
  //         {
  //           title: 'Under Maintenance',
  //           route: 'misc-under-maintenance',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Error',
  //           route: 'misc-error',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //   ],
  // },
]
